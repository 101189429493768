<template>
  <TransitionRoot :show="true">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-5">
      <TransitionChild as="template">
        <div class="fixed inset-0 bg-black opacity-40 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="pointer-events-auto relative w-screen transition-all duration-500" :class="addOrdersView && !addOrderLoad ? 'max-w-6xl' : 'max-w-md'">
                <!-- <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0"> -->
                  <!-- <div class="absolute top-0 left-0 -ml-8 flex pt-6 pr-2 sm:-ml-10 sm:pr-4">
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                      <span class="sr-only">Zamknij panel</span>
                      <XIcon :class="[addOrderLoad ? 'hidden' : '', 'h-6 w-6']" @click="$emit('close')" aria-hidden="true" />
                    </button>
                  </div> -->
                <!-- </TransitionChild> -->
                <div :class="[addOrdersView ? 'overflow-y-hidden' : 'overflow-y-scroll', 'flex h-full justify-between flex-col bg-white relative z-30 shadow-xl']">
                  <div class="relative" v-if="!addOrdersView">
                    <Transition name="fade">
                      <div v-if="blockModal.state && !ownChange && !loadingOrders && !sendingMessages" class="bg-gray-400 bg-opacity-70 h-full w-full absolute z-50 flex justify-center ">
                        <div style="top: 40%;" class="bg-white sticky w-64 h-44 p-4 rounded-lg shadow-xl flex justify-center items-center flex-col">
                          <div class="mx-auto mb-2 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-11 sm:w-11">
                            <ExclamationIcon class="h-8 w-8 text-yellow-600" aria-hidden="true" />
                          </div>
                          <div class="font-medium text-gray-700">Trasa została zaktualizowana</div>
                          <div v-if="blockModal.updatedBy.length > 0" class="text-gray-500 text-sm">przez: {{blockModal.updatedBy}}</div>
                          <button @click="refreshModalData()" :class="['transition-all mt-3 duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm flex gap-1 items-center']"><RefreshIcon class="h-4 w-4 text-white " aria-hidden="true" />Odśwież</button>
                        </div>
                      </div>
                    </Transition>
                    <Transition name="fade">
                      <div v-if="messagesConfirm && !ownChange && !loadingOrders && !sendingMessages" :class="[orders.length > 1 ? 'h-full' : 'h-screen', 'bg-gray-400 bg-opacity-70 w-full absolute z-50 flex justify-center']">
                        <div style="top: 40%;" class="bg-white sticky w-64 h-44 p-4 rounded-lg shadow-xl flex justify-center items-center flex-col">
                          <div class="mx-auto mb-2 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-11 sm:w-11">
                            <ExclamationIcon class="h-8 w-8 text-yellow-600" aria-hidden="true" />
                          </div>
                          <div class="font-medium text-gray-700 text-center text-sm">Czy na pewno chcesz wysłać wiadomości z potwierdzeniem?</div>
                          <div class="flex gap-2">
                            <button @click="messagesConfirm = false" :class="['transition-all mt-3 duration-300 bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 rounded-md border border-red-300 py-2 px-4 text-sm font-medium text-white shadow-sm flex gap-1 items-center']">Anuluj</button>
                            <button @click="sendConfirmMessages()" :class="['transition-all mt-3 duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm flex gap-1 items-center']"><MailIcon class="h-4 w-4 text-white " aria-hidden="true" />Wyślij</button>
                          </div>
                        </div>
                      </div>
                    </Transition>
                    <div class="px-8 mt-6 flex justify-between">
                      <DialogTitle class="font-semibold text-gray-500 select-none uppercase text-base">Aktualnie dodane zamówienia</DialogTitle>
                      <button type="button" class=" text-gray-600 hover:text-gray-300 outline-none focus:outline-none ring-0 focus:ring-0 transition-all duration-500" @click="open = false">
                        <span class="sr-only">Zamknij panel</span>
                        <XIcon :class="[addOrderLoad ? 'hidden' : '', 'h-6 w-6']" @click="$emit('close')" aria-hidden="true" />
                      </button>
                    </div>
                    <div class="relative mt-3 z-10 flex-1 pl-8 pr-6 ">
                      <div v-if="!loadingOrders && !sendingMessages">
                        <div v-if="!massEditDeliveryInformation" :class="[reorder ? 'justify-end' : 'justify-between', 'flex items-center gap-2']">
                          <div class="flex gap-2 justify-between w-full mb-2 " :class="this.reorder ? 'flex-col' : ''">
                            <button :disabled="blockModal.state" v-if="$route.path !== '/dashboard/routes/print' && $route.path !=='/dashboard/drivers/routes' && route.statusId === 1 && !reorder && !switchLoading" @click.prevent="changeView()" type="button" class="justify-center w-full transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-2.5 py-1.5 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                              <PlusCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              {{orders.length > 0 ? 'Dodaj więcej zamówień' : 'Dodaj zamówienia'}}
                            </button>
                            <button :disabled="blockModal.state" v-if="$route.path !== '/dashboard/routes/print' && $route.path !=='/dashboard/drivers/routes' && route.statusId === 1 && !switchLoading && reorder" @click.prevent="reOrderWholeRoute()" type="button" class="bg-yellow-100 text-yellow-500 hover:bg-yellow-200 justify-center w-full transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2 select-none">
                              <SwitchVerticalIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              Obróć kolejność
                            </button>
                            <button :disabled="blockModal.state" v-if="$route.path !== '/dashboard/routes/print' && $route.path !=='/dashboard/drivers/routes' && route.statusId === 1 && !switchLoading" @click.prevent="reOrderSwitch()" type="button" class="justify-center w-full transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-2.5 py-1.5 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 select-none">
                              <SwitchVerticalIcon v-if="!this.reorder" class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              <XIcon v-if="this.reorder" class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              {{!this.reorder ? 'Zmień kolejność' : 'Porzuć zmiany'}}
                            </button>
                          </div>
                        </div>
                        <div v-if="!this.reorder && !massEditDeliveryInformation">
                          <button :disabled="blockModal.state || blockSendConfirmMessages() || checkIfDeliveryCodes()" v-if="$route.path !== '/dashboard/routes/print' && $route.path !=='/dashboard/drivers/routes' && !switchLoading && route.statusId === 1" @click.prevent="messagesConfirm = true" type="button" :class="[blockSendConfirmMessages() || checkIfDeliveryCodes() ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'bg-green-100 text-green-600 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2']" class="justify-center w-full transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-3 inline-flex items-center px-2.5 py-1.5  select-none">
                            <TruckIcon class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                            <span v-if="!blockSendConfirmMessages() && checkIfDeliveryCodes()">Brak wygenerowanych kodów odbioru</span>
                            <span v-if="!blockSendConfirmMessages() && !checkIfDeliveryCodes()">Wyślij potwierdzenia dostawy</span>
                            <span v-if="blockSendConfirmMessages()">Wysłano już wszystkie potwierdzenia</span>
                          </button>
                        </div>
                        <LoadingSpinnerHub v-if="switchLoading" />
                        <div v-if="!switchLoading">
                          <ul role="list" :class="[(index != orders.length-1) ? 'mb-6' : '', 'relative grid grid-rows-1 gap-x-6 xl:gap-x-8']" v-for="(order, index) in orders" :key="index">
                            <li :ref="`order-${index}`" :class="[lockMassUpdate ? 'opacity-30 pointer-events-none' : '', indexOrder === index ? 'border-green-500' : '', 'transition-all duration-300 overflow-hidden rounded-xl border-2 border-gray-200']">
                              <!-- <div :class="['px-6 py-4 flex justify-between items-center gap-x-4 border-b border-gray-900/5', $route.path === '/dashboard/routes/archives' ? checkConfirmedDate(order, route) ? 'bg-green-100' : 'bg-red-100' : '']"> -->
                              <div :class="['px-6 py-4 flex justify-between items-center gap-x-4 border-b border-gray-900/5',   $route.path === '/dashboard/routes/archives' ? order.statusId === 6 ? 'bg-green-100' : 'bg-red-100' : '']">
                                <div class="flex gap-2 items-center">
                                  <div v-if="reorder">
                                    <ChevronUpIcon v-if="index !== 0" @click.prevent="changeOrder('up', index)" class="tranisiton-all duration-300 h-5 w-5 text-gray-500 hover:text-gray-400 cursor-pointer" />
                                    <ChevronDownIcon v-if="index !== orders.length - 1"  @click.prevent="changeOrder('down', index)" class="tranisiton-all duration-300 h-5 w-5 text-gray-500 hover:text-gray-400 cursor-pointer" />
                                  </div>
                                  <div class="text-sm font-medium leading-6 text-gray-900 select-none" v-tooltip="`${prod}`" v-for="prod in createProductList(order).slice(0,1)" :key="prod.id">
                                    <!-- <span @click.prevent="addConfirmDate(order)" class="mr-4">Oznacz jako dostarczone (confirmed)</span> -->
                                    {{prod.length > 25 ? prod.slice(0, 25) + '...' : prod}}
                                  </div>
                                </div>
                                <div class="flex gap-4 flex-row items-center">
                                    <!-- <a v-if="order.delivery.signature !== null" @click="debugBase64(order.delivery.signature.base64)">
                                      <PencilAltIcon v-tooltip="`Wyświetl podpis`" class="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500"/>
                                    </a>
                                    <a v-if="order.delivery.image !== null" :href="order.delivery.image !== undefined ? order.delivery.image.url : '#'" target="_blank">
                                      <PhotographIcon v-tooltip="`Wyświetl zdjęcie`" class="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500"/>
                                    </a> -->
                                  <button v-if="!massEditDeliveryInformation" :disabled="blockModal.state" @click="openDescription(index)" class="flex text-gray-500 ">
                                    <EyeIcon v-tooltip="`Wyświetl komentarz`" v-if="!this.toggleDescription[index].open" class="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500" />
                                    <EyeOffIcon v-tooltip="`Schowaj komentarz`" v-else class="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500" />
                                  </button>
                                  <router-link tabindex="-1" :to="`/dashboard/orders/add?edit=${order.id}`" target="_blank" class="flex text-gray-500 ">
                                    <ExternalLinkIcon v-tooltip="`Przejdź bezpośrednio do zamówienia`" class="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500" />
                                  </router-link>
                                  <Menu v-if="!massEditDeliveryInformation && ($route.path === '/dashboard/routes/archives' || $route.path === '/dashboard/routes/list' || $route.path === '/dashboard/routes/calendar')" as="div" class="relative inline-block text-left">
                                    <div>
                                      <MenuButton :disabled="blockModal.state" class="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                        <span class="sr-only">Open options</span>
                                        <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                                      </MenuButton>
                                    </div>
                                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                      <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div>
                                          <MenuItem v-if="route.statusId === 2 && order.statusId !== 6" v-slot="{ active }">
                                            <a href="#" @click="updateOrder(order)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2.5 text-sm transition-all duration-300']">
                                              <CheckIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                                              Dostarczone
                                            </a>
                                          </MenuItem>
                                          <MenuItem v-slot="{ active }">
                                            <a @click="openCalendarView(index)" v-if="$route.path !== '/dashboard/drivers/routes'" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2.5 text-sm transition-all duration-300 gap-3 select-none cursor-pointer']">
                                              <CalendarIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                                              {{!this.calendarsView[index].view ? 'Włącz edycję dostawy' : 'Wyłącz edycję dostawy'}}
                                            </a>
                                          </MenuItem>
                                        </div>
                                        <!-- <div class="py-1"> -->
                                        <div v-if="route.statusId !== 3">
                                          <MenuItem v-slot="{ active }">
                                            <a v-if="$route.path !== '/dashboard/drivers/routes'" @click="deleteOrder(order)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2.5 text-sm transition-all duration-300 gap-3']">
                                              <TrashIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                                              Usuń zamówienie
                                            </a>
                                          </MenuItem>
                                        </div>
                                        <div>
                                          <div v-if="order.delivery.signature !== undefined && order.delivery.signature !== null">
                                            <MenuItem v-slot="{ active }" :class="order.delivery.signature !== undefined && order.delivery.signature !== null ? '' : 'pointer-events-none opacity-30 select-none'">
                                              <a @click="debugBase64(order.delivery.signature.base64)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer group flex items-center px-4 py-2.5 text-sm uppercase transition-all duration-300']">
                                                <PencilAltIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                                                Podpis 
                                              </a>
                                            </MenuItem>
                                          </div>
                                          <div v-else class="flex items-center px-4 py-2.5 text-sm font-semibold gap-3 text-gray-500 opacity-20 select-none pointer-events-none">
                                            <PencilAltIcon class="h-5 w-5" aria-hidden="true" />
                                            BRAK PODPISU
                                          </div>
                                          <div v-if="order.delivery.image != null">
                                            <div v-for="(img, index) in order.delivery.image.url" :key="index" class="flex items-center">
                                            <MenuItem as="div" v-slot="{ active }" class="flex items-center w-4/5">
                                                <a :href="img" :target="order.delivery.image !== undefined ? '_blank' : ''" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2.5 text-sm w-full transition-all duration-300']">
                                                  <PhotographIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                                                  Zdjęcie {{index+1}}
                                                </a>
                                              </MenuItem>
                                              <div class="w-1/5 flex items-center justify-center" v-if="order.delivery.image.location != null">
                                                <a :href="`http://www.google.com/maps/place/${order.delivery.image.location.coords.latitude+','+order.delivery.image.location.coords.longitude}`" target="_blank" rel="noopener noreferrer">
                                                  <LocationMarkerIcon v-tooltip="`Wyświetl lokalizację zrobionego zdjęcia`" class="w-5 h-5 text-gray-400 hover:text-gray-500 transition-all duration-300" />
                                                </a>
                                              </div>
                                              <div v-else class="w-1/5 flex items-center justify-center">
                                                <LocationMarkerIcon v-tooltip="`Brak lokalizacji dla zdjęcia`" class="w-5 h-5 text-gray-400 hover:text-gray-500 transition-all duration-300" />
                                              </div>
                                            </div>
                                          </div>
                                          <div v-else class="flex items-center px-4 py-2.5 text-sm font-semibold gap-3 text-gray-500 opacity-20 select-none pointer-events-none">
                                            <PhotographIcon class="h-5 w-5" aria-hidden="true" />
                                            BRAK ZDJĘĆ
                                          </div>
                                        </div>
                                      </MenuItems>
                                  </transition>
                                  </Menu> 
                                </div>
                              </div>
                              <Transition name="fade">
                              <dl v-if="calendarsView[index] != null && !calendarsView[index].view" class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                <!-- <div v-if="order.statusId === 4" class="flex justify-center gap-x-4 py-3">
                                  <dd class="text-gray-700">
                                    <button @click="changeToZwrot(order)" type="button" class="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300">NAPRAW ZAMÓWIENIE</button>
                                  </dd>
                                </div> -->
                                <div class="flex justify-between gap-x-4 py-3">
                                  <dt class="text-gray-500 select-none uppercase font-semibold">Status zamówienia:</dt>
                                  <dd class="text-gray-700">
                                    <span v-tooltip="`Status: ${getStatusById(order.statusId).name} - Informacje: ${getStatusById(order.statusId).info}`" :class="{'cursor-pointer inline-flex w-full justify-center items-center rounded-md px-2 py-1 text-xs font-medium': true, 'bg-yellow-400 text-white': order.statusId === 1, 'bg-yellow-600 text-yellow-100': order.statusId === 2, 'bg-blue-500 text-blue-100': order.statusId === 3, 'bg-purple-400 text-purple-100': order.statusId === 4, 'bg-pink-500 text-pink-100': order.statusId === 5, 'bg-green-500 text-green-100': order.statusId === 6, 'bg-purple-500 text-purple-100': order.statusId === 7, 'bg-pink-800 text-pink-100': order.statusId === 400, 'bg-orange-400 text-orange-100': order.statusId === 401, 'bg-teal-500 text-teal-100': order.statusId === 402, 'bg-gray-400 text-gray-100': order.statusId === 403, 'bg-gray-500 text-gray-100': order.statusId === 404, 'bg-red-600 text-red-100': order.statusId === 300, 'bg-gray-300 text-gray-100': order.statusId === 8}">
                                      {{order.statusId === 2 ? 'Kompletowanie' : getStatusById(order.statusId).name}}
                                    </span>
                                  </dd>
                                </div>
                                <div class="flex justify-between gap-x-4 py-3">
                                  <dt class="text-gray-500 select-none">Dane osobowe:</dt>
                                  <dd class="text-gray-700">
                                    <span class="text-right">{{ order.client.name !== undefined && order.client.name !== null ? order.client.name : '---' }}</span>
                                  </dd>
                                </div>
                                <div class="flex justify-between gap-x-4 py-3">
                                  <dt class="text-gray-500 select-none">Dostawa:</dt>
                                  <dd class="flex items-start gap-x-2">
                                    <div :class="order.delivery.date.dateConfirmed !== undefined && order.delivery.date.dateConfirmed ? 'bg-green-50 text-green-500' : order.delivery.date.dateConfirmationSent !== undefined && order.delivery.date.dateConfirmationSent ? 'bg-blue-50 text-blue-500' : 'bg-red-50 text-red-500'" class="uppercase rounded-md px-2.5 py-1 bg-gray-100 text-gray-800 text-xs font-medium w-fit">{{order.delivery.date.dateConfirmed !== undefined && order.delivery.date.dateConfirmed ? "Potwierdzono dostawę" : order.delivery.date.dateConfirmationSent !== undefined && order.delivery.date.dateConfirmationSent ? "Wysłano potwierdzenie" : "Nie wysłano potwierdzenia"}}</div>
                                  </dd>
                                </div>
                                <div class="flex justify-between gap-x-4 py-3">
                                  <span class="text-gray-500 select-none">Adres dostawy:</span>
                                  <div class="flex w-40">
                                    <span class="truncate">{{ order.delivery.address !== undefined && order.delivery.address !== null ? order.delivery.address : '---' }}</span>
                                  </div>
                                </div>
                                <div class="flex justify-between gap-x-4 py-3">
                                  <dt class="text-gray-500 select-none">Miasto dostawy:</dt>
                                  <dd class="flex items-start gap-x-2">
                                    <div class="font-medium text-gray-900">{{ order.delivery.city !== undefined && order.delivery.city !== null ? order.delivery.city : '---' }}</div>
                                  </dd>
                                </div>
                                <div class="flex justify-between gap-x-4 py-3">
                                  <dt class="text-gray-500 select-none">Kod pocztowy:</dt>
                                  <dd class="flex items-start gap-x-2">
                                    <div class="font-medium text-gray-900">{{ order.delivery.postCode !== undefined && order.delivery.postCode !== null ? order.delivery.postCode : '---' }}</div>
                                  </dd>
                                </div>
                                <div v-if="!calendarsView[index].view" class="flex justify-between gap-x-4 py-3 opacity-50">
                                  <dt class="text-gray-500 select-none">Planowana data dostawy:</dt>
                                  <dd class="flex items-start gap-x-2">
                                    <div class="font-medium text-gray-900">{{ order.delivery.date.planned !== undefined && order.delivery.date.planned !== null ? moment.unix(order.delivery.date.planned.unix).format("DD.MM.YYYY") : 'Brak ustalonej daty' }} </div>
                                  </dd>
                                </div>
                                <div v-if="!calendarsView[index].view" class="flex justify-between gap-x-4 py-3 opacity-50">
                                  <dt class="text-gray-500 select-none">Planowana godzina dostawy:</dt>
                                  <dd class="flex items-start gap-x-2">
                                    <div class="font-medium text-gray-900">{{ order.delivery.date.planned != null && order.delivery.date.planned.fromHourUnix != null && order.delivery.date.planned.toHourUnix != null ? moment.unix(order.delivery.date.planned.fromHourUnix).format("HH:mm") + ' - ' + moment.unix(order.delivery.date.planned.toHourUnix).format('HH:mm')  : '--' }} </div>
                                  </dd>
                                </div>
                                <div v-if="!calendarsView[index].view && order.confirmation != null" class="opacity-50">
                                  <div class="flex justify-between gap-x-4 py-3">
                                    <dt class="text-gray-500 select-none">Kod odbioru:</dt>
                                    <dd class="flex items-start gap-x-2">
                                      <div class="font-medium text-gray-900">{{order.confirmation.code}}</div>
                                    </dd>
                                  </div>
                                </div>
                                <div v-if="!calendarsView[index].view && order.confirmation == null" class="opacity-50">
                                  <div class="flex justify-between gap-x-4 py-3">
                                    <dt class="text-gray-500 select-none">Kod odbioru:</dt>
                                    <dd class="flex items-start gap-x-2">
                                      <div class="font-semibold text-red-500 uppercase">Brak wydanej paczki</div>
                                    </dd>
                                  </div>
                                </div>
                                <div v-if="!calendarsView[index].view && order.confirmation != null" class="opacity-50">
                                  <div class="flex justify-between gap-x-4 py-3">
                                    <dt class="text-gray-500 select-none">Status odbioru:</dt>
                                    <dd class="flex items-start gap-x-2">
                                      <span v-if="order.confirmation.status != null" v-tooltip="`${order.confirmation.status === 0 ? 'Zamówienie nie zostało jeszcze dostarczone.' : '', order.confirmation.status === 1 ? 'Kod odbioru został podany przez klienta.' : '', order.confirmation.status === 2 ? 'Kod odbioru nie został podany.' : ''}`" :class="{'uppercase inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-semibold': true, 'text-yellow-700 bg-yellow-100': order.confirmation.status === 0, 'text-indigo-700 bg-indigo-100': order.confirmation.status === 1, 'text-red-700 bg-red-100': order.confirmation.status === 2}">
                                        {{smsStatuses[order.confirmation.status].name}}
                                      </span>
                                      <span v-else :class="{'uppercase inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium text-gray-700 bg-gray-100': true}">
                                        Nie wydano zamówienia
                                      </span>
                                    </dd>
                                  </div>
                                </div>
                                <div v-if="!calendarsView[index].view && order.confirmation == null">
                                  <div class="flex justify-between gap-x-4 py-3">
                                    <dt class="text-gray-500 select-none">Status odbioru:</dt>
                                    <dd class="flex items-start gap-x-2">
                                      <div class="font-semibold text-red-500 uppercase">Brak wydanej paczki</div>
                                    </dd>
                                  </div>
                                </div>
                              </dl>
                              </Transition>
                              <div v-if="calendarsView[index] != null && calendarsView[index].view" class="px-6 pb-4 flex flex-col">
                                <div :class="['flex flex-col items-center', calendarsView[index].date ? 'h-96' : '']">
                                  <div class="mt-3">
                                    <div class="flex gap-1 text-xs font-semibold leading-6 text-gray-600 select-none hover:text-gray-300 transition-all duration-300 cursor-pointer">
                                      Planowana data przyjazdu
                                    </div>
                                    <VueDatePicker text-input :uid="`dp-date-${index}`" locale="pl" @open="deliveryScheduleEditor(index, 'date')" @closed="deliveryScheduleEditor(index, 'date')" :month-change-on-scroll="false" auto-apply placeholder="np.: 6:00" v-model="this.plannedDate[index]" :enable-time-picker="false" :min-date="moment.unix(route.date.start.seconds).toDate()" :max-date="moment.unix(route.date.end.seconds).toDate()" />
                                  </div>
                                </div>
                                <div :class="['flex flex-col items-center', calendarsView[index].fromHour ? 'h-96' : '']">
                                  <div class="mt-3">
                                    <div class="flex gap-1 text-xs font-semibold leading-6 text-gray-600 select-none hover:text-gray-300 transition-all duration-300 cursor-pointer">
                                      Planowany przyjazd od
                                    </div>
                                    <VueDatePicker @update:model-value="handleInputChange(index, 'fromHourUnix')"  text-input :uid="`dp-hourFrom-${index}`" @open="deliveryScheduleEditor(index, 'fromHour')" @closed="deliveryScheduleEditor(index, 'fromHour')" :month-change-on-scroll="false" auto-apply placeholder="np.: 6:00" v-model="this.plannedFromHour[index]" time-picker />
                                  </div>
                                </div>
                                <div :class="['flex flex-col items-center mb-3', calendarsView[index].toHour ? 'h-96' : '']">
                                  <div class="mt-3">
                                    <div class="flex gap-1 text-xs font-semibold leading-6 text-gray-600 select-none hover:text-gray-300 transition-all duration-300 cursor-pointer">
                                      Planowany przyjazd do
                                    </div>
                                    <VueDatePicker @update:model-value="handleInputChange(index, 'toHourUnix')"  text-input :uid="`dp-hourTo-${index}`" @open="deliveryScheduleEditor(index, 'toHour')" @closed="deliveryScheduleEditor(index, 'toHour')" :month-change-on-scroll="false" auto-apply placeholder="np.: 21:00" v-model="this.plannedToHour[index]" time-picker />
                                  </div>
                                </div>
                                <div v-if="!massEditDeliveryInformation">
                                  <div class="relative mb-3">
                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                      <div class="w-full border-t border-gray-300" />
                                    </div>
                                    <div class="relative flex justify-center">
                                      <span class="bg-white px-2 text-gray-500">
                                        <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </span>
                                    </div>
                                  </div>
                                  <div class="flex justify-center gap-3">
                                    <button :disabled="blockModal.state" @click="calendarsView[index].view = false;" type="button" class="select-none text-sm font-semibold leading-6 text-gray-900 hover:text-gray-400 transition-all duration-300">
                                      Zamknij
                                    </button>
                                    <button :disabled="blockModal.state" @click="addPlannedDate(order, index)" class=" select-none rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300">
                                      Zapisz zmiany
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform -translate-y-5" enter-to-class="" leave-active-class="transition ease-in duration-200" leave-from-class="" leave-to-class="transform -translate-y-5">
                              <div class="relative my-3" v-if="this.toggleDescription[index].open">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                  <div class="w-full border-t border-gray-300" />
                                </div>
                                <div class="relative flex justify-center">
                                  <span class="bg-white px-2 text-gray-400">
                                    <ChevronDownIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <!-- <ChevronUpIcon v-if="!this.toggleDescription[index].open" class="h-6 w-6 text-gray-400" aria-hidden="true" /> -->
                                  </span>
                                </div>
                              </div>
                            </transition>
                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform -translate-y-5" enter-to-class="" leave-active-class="transition ease-in duration-200" leave-from-class="" leave-to-class="transform -translate-y-5">
                              <div v-if="this.toggleDescription[index].open" class="flex flex-col space-y-4 overflow-hidden">
                                <div class="min-w-0 flex-1">
                                  <div class="flex justify-between select-none">
                                    <label for="email" class="block text-xs font-medium leading-6 text-gray-900">Komentarz wewnętrzny</label>
                                    <!-- <span v-if="$route.path !== '/dashboard/drivers/routes'" class="text-xs leading-6 text-gray-500" id="email-optional">Opcjonalne</span> -->
                                  </div>
                                  <div class="overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-indigo-600" v-for="prod in createCommentList(order).slice(0,1)" :key="prod.id">
                                  <span class="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700 break-all">
                                    {{prod.length > 0 ? prod : '---'}}
                                  </span>
                                  </div>
                                </div>
                                <div class="min-w-0 flex-1">
                                  <div class="flex justify-between select-none">
                                    <label for="email" class="block text-xs font-medium leading-6 text-gray-900">Opis zamówienia</label>
                                    <span v-if="$route.path !== '/dashboard/drivers/routes'" class="text-xs leading-6 text-gray-500" id="email-optional">Opcjonalne</span>
                                  </div>
                                  <div class="relative rounded-xl border border-gray-200">
                                    <div class="overflow-hidden rounded-lg shadow-sm ring-inset focus-within:ring-2 focus-within:ring-indigo-600">
                                      <label for="comment" class="sr-only">Dodaj swój komentarz</label>
                                      <textarea :readonly="$route.path === '/dashboard/drivers/routes'" v-model="order.description" rows="3" name="comment" id="comment" :class="[$route.path === '/dashboard/drivers/routes' ? 'pointer-events-none' : '', 'block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900  placeholder-gray-400 focus:ring-0 sm:text-sm sm:leading-6']" :placeholder="route.path === '/dashboard/drivers/routes' ? 'Dodaj swój komentarz...' : 'Brak komentarzu dla zamówienia...'" />
                                      <div class="py-2" aria-hidden="true">
                                        <div class="py-px">
                                          <div class="h-9" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
                                      <div class="flex-shrink-0">
                                        <button :disabled="disableDescription || blockModal.state" v-if="$route.path !== '/dashboard/drivers/routes'" @click.prevent="addOrderDescription(order, index)" type="submit" :class="[disableDescription ? 'opacity-50 select-none pointer-events-none' : '', 'transition-all duration-300 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">Zastosuj</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </transition>
                          </ul>
                        </div>
                        <div v-if="!this.reorder" class="my-2">
                          <button :disabled="blockModal.state" @click.prevent="groupEditDeliveryInformation" v-if="$route.path !== '/dashboard/routes/print' && $route.path !=='/dashboard/drivers/routes' && !switchLoading && route.statusId === 1" type="button" :class="[!massEditDeliveryInformation ? 'bg-yellow-100 text-yellow-600 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus-visible:outline-yellow-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus-visible:outline-gray-600']" class="justify-center w-full transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5 select-none">
                            <PencilAltIcon class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                            <span v-if="!massEditDeliveryInformation">Masowa edycja dostawy</span>
                            <span v-if="massEditDeliveryInformation">Wyłącz masową edycję</span>
                          </button>
                        </div>
                        <div v-if="massEditDeliveryInformation">
                          <button @click.prevent="massUpdatePlannedDate" :disabled="blockModal.state" type="button" :class="[lockMassUpdate ? 'opacity-30 pointer-events-none' : '', 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus-visible:outline-indigo-600']" class="justify-center w-full transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  mb-3 inline-flex items-center px-2.5 py-1.5  select-none">
                            <CheckIcon class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                            <span>Zatwierdź zmiany</span>
                          </button>
                        </div>
                      </div>
                      <LoadingSpinnerHub class="mt-4" v-if="loadingOrders"/>
                      <LoadingSpinnerMessages class="mt-4" v-if="sendingMessages && !ownChange"/>
                      <EmptyState v-if="orders.length === 0 && !loadingOrders && !sendingMessages" />
                    </div>
                  </div>
                  
                  <!-- ADD ORDERS TABLE -->
                  <div v-if="addOrdersView">
                    <Transition name="fade">
                      <div v-if="blockModal.state && !ownChange && !addOrderLoad" class="bg-gray-400 bg-opacity-70 h-full w-full absolute z-50 flex justify-center ">
                        <div style="top: 40%;" class="bg-white sticky w-64 h-44 p-4 rounded-lg shadow-xl flex justify-center items-center flex-col">
                          <div class="mx-auto mb-2 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-11 sm:w-11">
                            <ExclamationIcon class="h-8 w-8 text-yellow-600" aria-hidden="true" />
                          </div>
                          <div class="font-medium text-gray-700">Trasa została zaktualizowana</div>
                          <div v-if="blockModal.updatedBy.length > 0" class="text-gray-500 text-sm">przez: {{blockModal.updatedBy}}</div>
                          <button @click="refreshModalData()" :class="['transition-all mt-3 duration-300 bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md border border-indigo-300 py-2 px-4 text-sm font-medium text-white shadow-sm flex gap-1 items-center']"><RefreshIcon class="h-4 w-4 text-white " aria-hidden="true" />Odśwież</button>
                        </div>
                      </div>
                    </Transition>
                    <div v-if="addOrderLoad" class="px-8 mt-8">
                      <DialogTitle class="text-lg font-medium text-gray-900 select-none">{{orders.length > 0 ? 'Dodaj więcej zamówień' : 'Dodaj zamówienia'}}</DialogTitle>
                    </div>
                    <Transition name="fade">
                      <LoadingSpinnerHub v-if="addOrderLoad" />
                    </Transition>
                    <div class="py-5 px-6" v-if="!addOrderLoad">
                      <button :disabled="blockModal.state" @click="changeView()" type="button" :class="[blockModal.state ? 'opacity-30 pointer-events-none' : '', 'inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-2.5 py-1.5 text-xs uppercase font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus:outline-none transition-all duration-300']">
                        <BackspaceIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Wróć do listy zamówień
                      </button>
                      <div class="my-4">
                        <div :class="['flex justify-between']">
                          <div class="flex items-center gap-2">
                            <Listbox class="w-80" v-if="groups.length > 0" as="div" v-model="selectedGroups">
                              <div class="relative">
                                <ListboxButton v-slot="{ value, open }" class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <span :class="[value == null ? 'opacity-50' : '', 'block truncate']">{{ selectedGroups == null ? 'Wybierz kod pocztowy...' : selectedGroups.name }}</span>
                                  <span :class="['pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2']">
                                    <ChevronDownIcon :class="[open ? 'transform rotate-180' : '', 'h-5 w-5 text-gray-400 transition-all duration-500']" aria-hidden="true" />
                                  </span>
                                </ListboxButton>

                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                  <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption as="template" v-for="(group, index) in groups" :key="index" :value="group" v-slot="{ active, selected }">
                                      <li @click="selectGroup(group)" :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                                        <span v-tooltip="`${group.name.length > 20 ? group.name : ''}`" :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate ml-0.5']">{{ group.name.slice(0, 20) + (group.name.length > 20 ? '...' : '') }}</span>
                                        <span :class="['absolute inset-y-0 left-0 flex items-center pl-2']">
                                          <img :src="group.country.flagUrl " class="h-3 w-5 border border-gray-400">
                                        </span>
                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      </li>
                                    </ListboxOption>
                                  </ListboxOptions>
                                </transition>
                              </div>
                            </Listbox>
                            <div v-else>
                              <div class="rounded-md bg-yellow-50 p-4">
                                <div class="flex">
                                  <div class="flex-shrink-0">
                                    <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                  </div>
                                  <div class="ml-3">
                                    <h3 class="text-sm font-medium text-yellow-800">Brak dostępnych grup kodów.</h3>
                                    <div class="text-sm text-yellow-700">
                                      <router-link class="text-md font-medium text-yellow-700 underline hover:text-yellow-600" to="/dashboard/postcode/add">Dodaj grupę</router-link> 
                                    </div>
                                  </div>
                                </div>
                              </div> 
                            </div>
                            <Transition name="fade">
                              <div v-if="showStatusFilters === true" class="">
                                <div class="flex flex-col lg:flex-row justify-center lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                                  <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 rounded-t-md">Opcje sortowania</label>
                                    <select v-model="pickedSort" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                      <option value="all">Brak</option>
                                      <option v-if="!this.selectedGroups || this.selectedGroups.country.code === 'Brak'" value="country">Kraj dostawy</option>
                                      <option value="code">Kod pocztowy</option>
                                      <option value="suggested">Planowana dostawa</option>
                                    </select>
                                  </div>
                                  <div v-if="this.pickedSort === 'country' && (!this.selectedGroups || this.selectedGroups.country.code === 'Brak')" class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 rounded-t-md">Wybierz kraj</label>
                                    <select v-model="pickedCountry" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                      <option v-for="country in countryList" :value="country.code" :key="country.id">{{country.name}}</option>
                                    </select>
                                  </div>
                                  <div v-if="(this.pickedSort === 'suggested' || this.pickedSort === 'code')" class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 rounded-t-md">Posortuj wyniki</label>
                                    <select v-model="pickedOption" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                      <option v-if="false" value="all">Wszystkie</option>
                                      <option value="desc">Malejąco</option>
                                      <option value="asc">Rosnąco</option>
                                    </select>
                                  </div>
                                  <button :disabled="blockModal.state" type="button" @click="this.getAllOrders()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    Zastosuj
                                  </button>
                                </div>
                              </div>
                            </Transition>
                          </div>
                          <!-- FILTER BUTTON -->
                          <div class="flex">
                            <div @click="showFilters()" class="mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                              <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                              <span class="text-xs font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                            </div>
                            <button :disabled="blockModal.state" v-if="showStatusFilters === false" @click="checkOrdersByDeliveryDate()" type="button" :class="{'transition ease-in-out duration-300 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2': true, 'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600': !ordersByDeliveryDate, 'bg-red-600 text-white hover:bg-red-500 focus-visible:outline-red-600': ordersByDeliveryDate}">
                              <span v-if="!ordersByDeliveryDate">Pokaż sugerowane do doręczenia {{createSuggestedDate(this.date)}}</span>
                              <span v-if="ordersByDeliveryDate">Pokaż wszystkie</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <!-- tabelka -->
                      <div class="overflow-hidden flex flex-col" v-if="allOrders.length > 0 && !load">
                        <div class="overflow-auto">
                          <div :class="[allOrders.length > 8 ? 'h-98 overflow-y-scroll' : '', 'inline-block min-w-full align-middle']">
                            <div class="overflow-hidden shadow border border-gray-200 rounded-lg" v-if="!loading">
                              <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Kraj</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">
                                        <div class="inline-flex w-20">
                                            <span class="truncate">
                                            Kod pocztowy
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Nazwa mebla</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Producent</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Status</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Sugerowana dostawa</th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                      <span class="sr-only">Data</span>
                                    </th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                      <span class="sr-only">Druk</span>
                                    </th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                      <span class="sr-only">Edycja</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white" v-for="(order, index) in ordersByDeliveryDate ? newCheckedOrders : allOrders" :key="index">
                                  <tr>
                                    <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                                        <div class="flex items-center">
                                            <img v-if="order.countryFlagURL.length>0" :src="order.countryFlagURL" v-tooltip="`${order.delivery.country.code}`" class="h-4 w-6 border border-gray-300">
                                            <span v-if="order.countryFlagURL.length === 0 && order.delivery.countryCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <div class="flex items-center">
                                            <span v-if="order.delivery.postCode.length>0">{{ order.delivery.postCode }}</span>
                                            <span v-if="order.delivery.postCode.length === 0 && order.delivery.postCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <div class="flex items-center">
                                          <div class="w-64 inline-flex">
                                            <span @click.prevent="openCommentsModal(order)" v-tooltip="`Zobacz komentarze wewnętrzne`" :class="[order.statusId === 400 && createCommentList(order)[0].length > 0 ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800', 'mr-1 truncate items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer']" v-for="prod in createProductList(order).slice(0,1)" :key="prod">{{prod}}</span>
                                            <span @click.prevent="openCommentsModal(order)" class="cursor-pointer" v-tooltip="`${createProductList(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="createProductList(order).length - 1 > 0">+ {{createProductList(order).length - 1}} więcej</span>
                                          </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                      <div class="inline-flex w-28 truncate items-center">
                                        <span class="truncate font-semibold text-gray-600" v-for="manu in getManufacturer(order).slice(0,1)" :key="manu">{{typeof manu === 'object' ? manu.name : manu}}</span>
                                        <span class="text-gray-900 font-semibold" v-tooltip="`${getManufacturer(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="getManufacturer(order).length - 1 > 0">+ {{getManufacturer(order).length - 1}} więcej</span>
                                      </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                      <span v-tooltip="`Status: ${getStatusById(order.statusId).name} - Informacje: ${getStatusById(order.statusId).info}`" :class="{'cursor-pointer inline-flex w-full justify-center items-center rounded-md px-2 py-1 text-xs font-medium': true, 'bg-yellow-400 text-white': order.statusId === 1, 'bg-yellow-600 text-yellow-100': order.statusId === 2, 'bg-blue-500 text-blue-100': order.statusId === 3, 'bg-purple-400 text-purple-100': order.statusId === 4, 'bg-pink-500 text-pink-100': order.statusId === 5, 'bg-green-500 text-green-100': order.statusId === 6, 'bg-purple-500 text-purple-100': order.statusId === 7, 'bg-pink-800 text-pink-100': order.statusId === 400, 'bg-orange-400 text-orange-100': order.statusId === 401, 'bg-teal-500 text-teal-100': order.statusId === 402, 'bg-gray-400 text-gray-100': order.statusId === 403, 'bg-gray-500 text-gray-100': order.statusId === 404, 'bg-red-600 text-red-100': order.statusId === 300, 'bg-gray-300 text-gray-100': order.statusId === 8}">
                                        {{order.statusId === 2 ? 'Kompletowanie' : getStatusById(order.statusId).name}}
                                      </span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <span v-if="order.delivery.date.suggested !== null">{{moment.unix(order.delivery.date.suggested.unix).format('LL')}}</span>
                                        <span v-if="order.delivery.date.suggested === null" class="opacity-40 uppercase font-medium select-none">Nie wskazano</span>
                                    </td>
                                    <td class="whitespace-nowrap">
                                      <div class="flex justify-center pr-2 items-center">
                                        <AnnotationIcon v-tooltip="`Data wpłynięcia zamówienia: ${order.origin != null ? order.origin.meta != null ? order.origin.meta.external != null ? moment.unix(order.origin.meta.external.dates.add_TimestampUNIX).format('LLL') : moment.unix(order.meta.created.unix).format('LLL') : moment.unix(order.meta.created.unix).format('LLL') : moment.unix(order.meta.created.unix).format('LLL')}`" class="cursor-pointer transition-all duration-300 h-5 w-5 text-gray-400 hover:text-gray-600 flex-shrink-0" aria-hidden="true" />
                                      </div>
                                    </td>
                                    <td class="whitespace-nowrap">
                                      <div class="flex justify-center pr-2 items-center text-gray-400 hover:text-gray-600 transition-all duration-300 cursor-pointer ">
                                        <EyeIcon v-tooltip="`Wyświetl informacje o zamówieniu.`" @click.prevent="openAdditionalInformation(index)" class="h-6 w-6" v-if="!this.toggleInformation[index].open" />
                                        <EyeOffIcon v-tooltip="`Schowaj informacje o zamówieniu.`" @click.prevent="openAdditionalInformation(index)" class="h-6 w-6" v-if="this.toggleInformation[index].open" />
                                      </div>
                                    </td>
                                    <td class="whitespace-nowrap">
                                      <div class="flex justify-center pr-3 items-center">
                                        <input :disabled="blockModal.state" v-tooltip="`Wybierz zamówienie`" :checked="isOrderChecked(order)" @change="checkOrder($event, order, index), calculateVolume($event, order)" name="order" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      </div>
                                    </td>
                                  </tr>
                                  <td colspan="12" v-if="this.toggleInformation[index].open" class="toggled bg-gray-100 border-t border-gray-200 px-6">
                                    <div class="py-5">
                                      <div class="flex justify-between">
                                        <div class=" gap-1 flex flex-col">
                                        <span class="mr-1 text-xs text-gray-500 uppercase font-semibold">Informacje o zamówieniu:</span>
                                          <div class="text-xs flex flex-col" v-for="(position, index) in createPositionsList(order)" :key="index">
                                            <span class="text-gray-500">Nazwa produktu: 
                                              <span class="font-bold">{{ position.item.product.name === undefined ? '?' : position.item.product.name }}</span>
                                            </span>                                                
                                            <span class="text-gray-500">Ilość:
                                              <span class="font-bold mr-2">{{ position.item.product.quantity === undefined ? '?' : position.item.product.quantity }}</span>
                                              <span class="text-gray-500">Ilość paczek:
                                                <span class="font-bold">{{ position.item.product.amountOfPackages === undefined ? '?' : position.item.product.amountOfPackages}}</span>
                                              </span>
                                            </span>                                                
                                            <span class="text-gray-500">Cena za sztukę (podatek wliczony w cenę): <span class="font-bold">{{position.item.product.pricePerItemTaxIncluded === undefined ? '?' : position.item.product.pricePerItemTaxIncluded}} {{position.item.product.currency === undefined ? '?' : position.item.product.currency}}</span></span>                                                
                                          </div>
                                        </div>
                                        <div class="flex self-end">
                                          <div class="flex flex-col">
                                            <span class="text-gray-500 text-xs">Data wpłynięcia zamówienia: <span class="font-bold">{{order.origin != null ? order.origin.meta != null ? order.origin.meta.external != null ? moment.unix(order.origin.meta.external.dates.add_TimestampUNIX).format('LLL') : moment.unix(order.meta.created.unix).format('LLL') : moment.unix(order.meta.created.unix).format('LLL') : moment.unix(order.meta.created.unix).format('LLL')}}</span></span>                                                
                                            <span class="text-gray-500 text-xs">Data wpłynięcia do systemu: <span class="font-bold">{{ moment.unix(order.meta.created.unix).format('LLL') }}</span></span>                                                
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td v-if="!blockModal.state && index == allOrders.length - 1 && !queryLimitReached" colspan="12">
                                    <div class="flex justify-center pt-3 pb-1">
                                      <InfiniteLoading v-tooltip="`W przypadku zatrzymania się ładowania, proszę nacisnąć kręcące się kółko.`" @click.prevent="loadMoreOrders" v-if="!loading && !queryLimitReached" @infinite="loadMoreOrders" />
                                    </div>
                                  </td>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <transition v-else name="fade">
                        <LoadingSpinnerHub v-if="load" :header="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></LoadingSpinnerHub>
                      </transition>
                      <EmptyState class="ml-2 mt-3 mb-3" v-if="allOrders.length === 0 && load === false" />
                      <div class="mt-5 text-right text-xs text-gray-500 select-none">Pozostała pojemność: 
                        <span class="text-xs text-gray-500 font-semibold"><span :class="[fullVolume > route.vehicle.attribs.payloadCapacity.value  ? 'text-red-500' : 'text-gray-500']">
                          {{ currency(this.fullVolume)}}</span> z {{route.vehicle.attribs.payloadCapacity.value }} m3</span>
                      </div>
                    </div>
                  </div>
                  
                  <!-- buttons -->
                  <div v-if="!blockModal.state && !messagesConfirm" :class="['flex border-t-2 p-3 sticky bottom-0 z-50 bg-white', reorder ? 'justify-between' : 'justify-end']">
                    <div class="ml-5" v-if="reorder">
                      <button :disabled="blockModal.state" @click.prevent="reOrderUpdate()" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Aktualizuj
                      </button>
                    </div>
                    <div v-if="!reorder" :class="[addOrdersView ? '' : 'mr-3', 'flex gap-2']">
                      <button :disabled="blockModal.state" v-if="!addOrdersView && showBackButton && $route.path !== '/dashboard/drivers/routes' && $route.path !== '/dashboard/routes/archives'" @click="returnEmit" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-white py-2 px-4 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline border border-gray-300 hover:border-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                        Zarządzaj
                      </button>
                      <!-- <button v-if="!addOrdersView && !loadingOrders" @click.prevent="specialUpdate()" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-red-500 py-2 px-4 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline border border-red-300 hover:border-red-200 text-white hover:text-black focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">Aktualizuj
                      </button> -->
                      <button :disabled="blockModal.state" v-if="!addOrdersView" @click.prevent="closeModal" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-white py-2 px-4 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline border border-gray-300 hover:border-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                        Zamknij
                      </button>
                      <button :disabled="blockModal.state || addOrderLoad" v-if="addOrdersView" @click.prevent="changeView()" :class="[addOrderLoad? 'opacity-20 pointer-events-none' : '', 'transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-white py-2 px-4 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline border border-gray-300 hover:border-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600']">
                        Wróć
                      </button>
                      <button v-if="addOrdersView" @click="addOrders()" type="button" :disabled="addOrderLoad || checkedOrders.length === 0 || blockModal.state" :class="[addOrderLoad ? 'opacity-20 pointer-events-none' : '', checkedOrders.length === 0 ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-600 text-base font-medium hover:bg-blue-700 focus:ring-blue-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm transition-all duration-300']">Dodaj
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
    <CommentsModal v-if="commentsModal.toggle" :order="commentsModal.data" @close="closeCommentsModal"></CommentsModal>
  </TransitionRoot>
</template>

<script>
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import currency from 'currency.js';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ExternalLinkIcon, AnnotationIcon, TrashIcon, CalendarIcon, PlusIcon, PlusCircleIcon, EyeIcon, EyeOffIcon, ReplyIcon, ExclamationIcon, FilterIcon, PhotographIcon, PencilAltIcon, RefreshIcon, TruckIcon, MailIcon, BackspaceIcon } from '@heroicons/vue/outline'
import { SwitchVerticalIcon, ArrowCircleLeftIcon, CheckCircleIcon, DotsVerticalIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/vue/solid'
import { MenuButton, MenuItem, MenuItems, Menu } from '@headlessui/vue'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import LoadingSpinnerMessages from '../components/LoadingSpinnerMessages.vue';
import EmptyState from './EmptyState.vue';
import CommentsModal from "@/components/CommentsInOrder.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'
import smsTemplates from '../smsTemplates.js'
import randomstring from 'randomstring';

export default {
    name: "OrdersModal",
    components: {
      LocationMarkerIcon, InfiniteLoading, ExternalLinkIcon, AnnotationIcon, PhotographIcon, PencilAltIcon, SwitchVerticalIcon, DotsVerticalIcon, PlusIcon, CommentsModal, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, BackspaceIcon, EmptyState, LoadingSpinnerHub, Listbox, ListboxButton, ListboxOption, ListboxOptions, MenuButton, MenuItem, MenuItems, Menu, VueDatePicker, ExclamationIcon, FilterIcon, TrashIcon, PlusCircleIcon, EyeIcon, EyeOffIcon, ReplyIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon, XIcon, CalendarIcon, CheckCircleIcon, ArrowCircleLeftIcon, RefreshIcon, TruckIcon, LoadingSpinnerMessages, MailIcon
    },
    props: {
      showBackButton: {
        type: Boolean,
        default: true
      },
      route: {
        type: Object,
      },
      sendingMessages: {
        type: Boolean
      },
      blockModal: {
        type: Object,
        default() {
          return { state: false, updatedBy: '' }
        }
      }
    },
    data() {
      return {
        massEditDeliveryInformation: false,
        description: '',
        disableDescription: false,
        changeDatePicker: false,
        plannedDate: [],
        plannedFromHour: [],
        plannedToHour: [],
        descriptions: [],
        toggleDescription: {},
        calendarsView: [],
        moment: moment,
        newCheckedOrders:[],
        ordersByDeliveryDate: false,
        loadProducts:false,
        orders: [],
        allOrders: [],
        toggleInformation: {},
        checkedOrders: [],
        fullVolume: 0,
        addOrdersView: false,
        messagesConfirm: false,
        currency: currency,
        queryLimitOrders: 25,
        queryLimit: 10,
        queryLimitReached: false,
        date:null,
        groups: [],
        selectedGroups: {},
        postcodesList: [],
        statuses: [],
        showStatusFilters: false,
        load: false,
        loading: false,
        loadingOrders: false,
        dbListener: null,
        showStatusFilters: false,
        countryList: [
          { id: 0, name: 'Wszystkie', code: 'all'},
          { id: 1, name: 'Polska', code: 'PL'},
          { id: 2, name: 'Niemcy', code: 'DE'},
          { id: 3, name: 'Francja', code: 'FR'},
          { id: 4, name: 'Luksemburg', code: 'LU'},
          { id: 5, name: 'Włochy', code: 'IT'},
          { id: 6, name: 'Holandia', code: 'NL'},
          { id: 7, name: 'Belgia', code: 'BE'},
          { id: 8, name: 'Austria', code: 'AT'},
        ],
        optionList: [
          { id: 1, name: 'Malejąco', type: 'desc'},
          { id: 2, name: 'Rosnąco', code: 'asc'},
        ],
        commentsModal:{
          toggle: false,
          data: null
        },
        pickedOption: "desc",
        pickedCountry: "all",
        pickedSort: "all",
        sortName: "creationDesc",
        sortType: {
            order: "desc",
            type: "meta.created.date"
        },
        pickedDateOfCreate: null,
        pickedDateOfCreateUnix: null,
        pickedDateOfDelivery: null,
        pickedDateOfDeliveryUnix: null,
        switchLoading: false,
        reorder: false,
        indexOrder: null,
        addOrderLoad: false,
        ownChange: false,
        smsStatuses: {},
        countriesData: [],
        isSelectedGroup: false,
        lastLoaded: false,
        disableListener: false,
        lockMassUpdate: false,
      }
    },
    beforeUnmount() {
      if(this.dbListener !== null){
        this.dbListener();
      }
    },
    methods: {
      // async fixConfirmationObject(order)
      // {
      //   try {
      //     for(let i=0; i<this.orders.length; i++)
      //     {
      //       if(this.orders[i].confirmation === undefined || this.orders[i].confirmation === null)
      //       {
      //         let confimationObject = {
      //           // confirmed: false,
      //           // skipped: false,
      //           status: 0,
      //           code: parseInt(randomstring.generate({
      //             length: 4,
      //             charset: 'numeric'
      //           })),
      //         };
      //         await db.collection('Orders').doc(this.orders[i].id).update({
      //           "confirmation": confimationObject
      //         })
      //       }
      //     }
      //   } catch (error)
      //   {
      //     console.log(error);  
      //   }
      // },
      handleInputChange(index, field)
      {
        if(field === 'fromHourUnix')
        {
          this.plannedToHour[index] = {
            hours: this.plannedFromHour[index].hours+3,
            minutes: 0
          };
        }
      },
      groupEditDeliveryInformation()
      {
        this.massEditDeliveryInformation = !this.massEditDeliveryInformation;
        this.calendarsView.forEach(calendar => {
          calendar.view = this.massEditDeliveryInformation;
        });
      },
      async getSMSHelpers() {
        this.smsStatuses = (await db.collection("SMSHelpers").doc("helpers").get()).data().codes
      },
      reOrderWholeRoute() {
        let newOrdersOrder = []
        for(let i=this.orders.length-1; i>=0; i--) {
          newOrdersOrder.push(this.orders[i])
        }
        this.orders = newOrdersOrder
      },
      blockSendConfirmMessages() {
        let block = true
        this.orders.forEach(order => {
          if(order.delivery.date.dateConfirmationSent === undefined || !order.delivery.date.dateConfirmationSent) {
            block = false
          }
        })
        return block
      },
      checkIfDeliveryCodes() {
        let deliveryCodes = false
        this.orders.forEach(order => {
          if(order.confirmation == null || order.confirmation.code == null) {
            deliveryCodes = true
          }
        });
        return deliveryCodes
      },
      async sendConfirmMessages() {
        try {
          let ordersWihoutConfirmationSent = []
          this.loadingOrders = true
          this.ownChange = true
          this.messagesConfirm = false
          for(let i=0; i<this.orders.length; i++)
          {
            let order = this.orders[i];
            if(order.delivery.date.dateConfirmationSent === undefined || !order.delivery.date.dateConfirmationSent) {
              let data = {
                messageType: 'notificationDayBeforeDelivery',
                return: this.orders[i].return == null ? false : this.orders[i].return,
                prefix: order.delivery.phone.prefix,
                orderId: order.id,
                key: order.elasticSearch.docId,
                shortenId: '',
                lang: 'pl',
                code: order.confirmation.code
              }

              if(order.delivery.phone.prefix.length > 0)
              {
                if(order.delivery.phone.prefix === "+48") {
                  data.lang = 'pl';
                }
                // Niemcy
                if(order.delivery.phone.prefix === "+49") {
                  data.lang = 'de';
                }
                // Francja
                if(order.delivery.phone.prefix === "+33") {
                  data.lang = 'fr';
                }
                // Luksemburg
                if(order.delivery.phone.prefix === "+352") {
                  data.lang = 'lu';
                }
                // Włochy
                if(order.delivery.phone.prefix === "+39") {
                  data.lang = 'it'
                }
                // Holandia
                if(order.delivery.phone.prefix === "+31") {
                  data.lang = 'nl';
                }
                // Belgia
                if(order.delivery.phone.prefix === "+32") {
                  data.lang = 'be';
                }
                // Austria
                if(order.delivery.phone.prefix === "+43") {
                  data.lang = 'at';
                }
              }

              let shortenId = randomstring.generate(10);
              const result = await db.collection('shortenedDeliveryLinks').doc(shortenId).get();
              if(!result.exists)
              {
                await db.collection('shortenedDeliveryLinks').doc(shortenId).set({
                  "prefix": data.prefix,
                  "orderId": data.orderId,
                  "key": data.key,
                  "lang": data.lang,
                })
              }
              else
              {
                shortenId = randomstring.generate(10);
                await db.collection('shortenedDeliveryLinks').doc(shortenId).set({
                  "prefix": data.prefix,
                  "orderId": data.orderId,
                  "key": data.key,
                  "lang": data.lang,
                })
              }
              data.shortenId = shortenId;
              const template = smsTemplates(data);
              data.prefix = "+48"
              const polishTemplate = smsTemplates(data)
              ordersWihoutConfirmationSent.push({id: order.id, to: order.delivery.phone.full.length == 0 ? order.client.phone.full : order.delivery.phone.full, message: template, polishMessage: polishTemplate, code: order.confirmation.code, prefix:  order.delivery.phone.prefix.length == 0 ? order.client.phone.prefix : order.delivery.phone.prefix})
            }
          }
          const result = await axios.post(`${this.$store.state.apiLink}/delivery/send/confirmation`, {
            orders: ordersWihoutConfirmationSent,
            route: this.route.id
          })
          await this.getOrdersFromRefs()
          this.loadingOrders = false
          this.ownChange = false
        } catch(error) {
          console.log(error);
          this.loadingOrders = false
          this.ownChange = false
        }
      },
      refreshModalData() {
        if(!this.ownChange) {
          this.$store.commit('setNotification',{
            show: true,
            head: "Pomyślnie odświeżono dane.",
            subheader: ``,
            success: true
          });
        }
        this.loadingOrders = true
        this.$emit('refreshModalData')
      },
      debugBase64(base64URL){
          var win = window.open();
          win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      },
      closeModal()
      {
        this.$emit("close");
      },
      async reOrderUpdate()
      {
        try
        {
          let newOrders = [];
          for(let i=0; i<this.orders.length; i++)
          {
            newOrders.push({
              index: i,
              ref: db.collection('Orders').doc(this.orders[i].id)
            })
          }
          if(this.orders.length === newOrders.length)
          {
            this.ownChange = true;
            this.loadingOrders = true
            let preparedOrders = [];
            for(let i=0; i<newOrders.length; i++) {
              preparedOrders.push({
                id: newOrders[i].ref.id,
                index: i
              });
            }
            const res = await axios.post(`${this.$store.state.herokuApiLink}/routes/${this.$store.state.herokuApiPath}/loadingSequence/get`, {
              vehicleId: this.route.vehicle.id,
              orders: preparedOrders,
            }, {
              timeout: 20000,
              headers: {
                  'x-api-jwt-token': this.$store.state.userData.jwt,
                  'x-api-key': process.env.VUE_APP_APIKEY,
              },
            });
            if(!res.data.success) throw "Something went wrong with loadingSequence"
            for(let i=0; i<Object.keys(res.data.payload.packages).length; i++) {
              let orderData = (await db.collection('Orders').doc(Object.keys(res.data.payload.packages)[i]).get()).data()
              if(orderData.packages == null) {
                db.collection('Orders').doc(Object.keys(res.data.payload.packages)[i]).update({
                  'packages': res.data.payload.packages[Object.keys(res.data.payload.packages)[i]]
                })
              }
            }
            await db.collection('Routes').doc(this.route.id).update({
              orders: newOrders,
              loadingSequence: res.data.payload.loadingSequence,
              "meta.lastModificationDate": moment().toDate(),
              "meta.name": this.$store.state.userData.name + ' ' + this.$store.state.userData.surname
            })
            this.reorder = false;
            this.$store.commit('setNotification',{
              show: true,
              head: "Kolejność zamówień została zmieniona!",
              subheader: ``,
              success: true
            });
          }
          else
          {
            this.ownChange = false;
            this.loadingOrders = false
            this.$store.commit('setNotification',{
              show: true,
              head: "Błąd podczas zapisywania.",
              subheader: `Coś poszło nie tak..`,
              success: false
            }); 
          }
        }
        catch (error)
        {
          console.log(error)
          this.ownChange = false;
          this.loadingOrders = false
          console.log(error); 
        }
      },
      reOrderSwitch()
      {
        this.reorder = !this.reorder
      },
      changeOrder(direction, index)
      {
        let x = direction === 'up' ? index-1 : index+1;
        let y = index;
        if(direction === 'up')
        {
          this.indexOrder = index-1;
        }
        else
        {
          this.indexOrder = index+1;
        }
        setTimeout(() => {
          this.indexOrder = null;
        }, 500);
        [ this.orders[x], this.orders[y] ] = [ this.orders[y], this.orders[x] ];
      },
      async getOrdersDetails()
      {
        try {
          for(let order in this.orders)
          {
            const imageRes = await db.collection("PhotosOfPackages").where("orderId", "==", this.order.id).get();
            if(!imageRes.empty)
            {
              imageRes.forEach(doc => {
                let current = doc.data();
                if(typeof current.img.url !== 'string')
                {
                  order.delivery.image = current;
                }
                else
                {
                  order.delivery.image = current.img.url;
                }
                if(current.location !== undefined)
                {
                  order.delivery.image = current.location;
                }
              });
            }
            else
            {
              order.delivery.image = null;
            };
            const signatureRes = await db.collection('Signatures').where("orderId", "==", this.order.id).get()
            if(!signatureRes.empty)
            {
              order.delivery.signature = signatureRes.docs[0].data().img;
            }
            else
            {
              order.delivery.signature = null;
            };
          }
        }
        catch (error) {
          console.log(error);
        }       
      },
      openCommentsModal(order){
        this.addOrdersView = false;
        this.commentsModal.toggle = true
        this.commentsModal.data = order
      },
      closeCommentsModal(){
        this.addOrdersView = true;
        this.commentsModal.toggle = false
        this.commentsModal.data = null
      },
      openHistoryModal(order){
        this.historyModal.toggle = true
        this.historyModal.data = order
      },
      closeHistoryModal(){
        this.historyModal.toggle = false
        this.historyModal.data = null
      },
      createCommentList(order)
      {
        const products = Object.entries(order.positions)
        let translatedListOfComments = [];
        products.forEach(element => {
            if(element[0])
            {
              translatedListOfComments.push(element[1].infos.onOrderCreation);
            }
        })

        return translatedListOfComments
      },
      async updateOrder(order)
      {
        const orderRef = db.collection("Orders").doc(order.id)
        orderRef.update({statusId: 6})

        this.$store.commit('setNotification',{
          show: true,
          head: "Zamówienie zostalo zmienione!",
          subheader: `Zamówienie jest dostarczone.`,
          success: true
        });
      
        this.emitClose()
        this.$store.commit('resetGlobalLoader');
        this.$router.push(`/dashboard/routes/list`)
      },
      openCalendarView(index)
      {
        if(!this.calendarsView[index].view)
        {
          for(let i=0; i<this.calendarsView.length; i++)
          {
            this.calendarsView[i].view = false;
            this.calendarsView[i].date = false;
            this.calendarsView[i].fromHour = false;
            this.calendarsView[i].toHour = false;
          }
          this.calendarsView[index].view = true;
        }
        else
        {
          this.calendarsView[index].view = false;
        }
      },
      deliveryScheduleEditor(index, type)
      {
        if(type === 'date')
        {
          if(!this.calendarsView[index].date)
          {
            this.calendarsView[index].date = true;
            this.calendarsView[index].fromHour = false;
            this.calendarsView[index].toHour = false;
          }
          else
          {
            this.calendarsView[index].date = false;
          }
        }
        else if(type === 'fromHour')
        {
          this.handleInputChange(index, 'fromHourUnix');
          if(!this.calendarsView[index].fromHour)
          {
            this.calendarsView[index].fromHour = true;
            this.calendarsView[index].date = false;
            this.calendarsView[index].toHour = false;
          }
          else
          {
            this.calendarsView[index].fromHour = false;
          }
        }
        else if(type === 'toHour')
        {
          if(!this.calendarsView[index].toHour)
          {
            this.calendarsView[index].toHour = true;
            this.calendarsView[index].date = false;
            this.calendarsView[index].fromHour = false;
          }
          else
          {
            this.calendarsView[index].toHour = false;
          }
        }
      },
      async massUpdatePlannedDate()
      {
        this.lockMassUpdate = true;
        try {
          let plannedDatesUpdate = [];
          for(let i=0; i<this.orders.length; i++)
          {
            const plannedDate = {
              asText: moment(this.plannedDate[i]).format("YYYY-MM-DD"),
              unix: moment(this.plannedDate[i]).unix(),
              dateObj: moment(this.plannedDate[i]).toDate(),
              dayOfWeek: moment(this.plannedDate[i]).day(),
              dayNum: moment(this.plannedDate[i]).date(),
              monthNum: moment(this.plannedDate[i]).month() + 1,
              year: moment(this.plannedDate[i]).year(),
              weekNum: moment(this.plannedDate[i]).week(),
              fromHourUnix: moment(this.plannedFromHour[i]).unix(),
              toHourUnix: moment(this.plannedToHour[i]).unix(), 
            };
            if ((plannedDate.toHourUnix < plannedDate.fromHourUnix) || isNaN(plannedDate.fromHourUnix) || isNaN(plannedDate.toHourUnix)) {
              console.error('Error: toHourUnix is lower than fromHourUnix');
              this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: `Godzina końcowa nie może być wcześniejsza niż godzina początkowa`,
                success: false
              });
              const el = this.$refs[`order-${i}`][0];

              if (el) {
                el.scrollIntoView({behavior: 'smooth'});
                this.indexOrder = i;
                setTimeout(() => {
                  this.indexOrder = null;
                  this.lockMassUpdate = false;
                }, 1500);
              }
              return;
            }
            plannedDatesUpdate.push({
              id: this.orders[i].id,
              plannedDate: plannedDate
            });
          }
          const res = await axios.post(`${this.$store.state.apiLink}/route/orders/massUpdatePlannedDate`, {
            dates: plannedDatesUpdate,
            route: this.route,
            byUser: {
              fullname: this.$store.state.userData.name + ' ' + this.$store.state.userData.surname,
              id: this.$store.state.userData.id
            }
          })
          if(res.data.success)
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona sukcesem!",
              subheader: `Planowana data dostawy dla zamówień została zmieniona.`,
              success: true
            });
            plannedDatesUpdate = [];
            setTimeout(async () => {
              this.groupEditDeliveryInformation();
              await this.getOrdersFromRefs();
            }, 1000);
            setTimeout(() => {
              this.lockMassUpdate = false;
            }, 1500);
          }
          else
          {
            plannedDatesUpdate = [];
            throw "Error: Something went wrong with massUpdatePlannedDate"
          }
        } 
        catch (error) {
          console.log(error);  
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: `Coś poszło nie tak..`,
            success: false
          });
          setTimeout(() => {
            this.lockMassUpdate = false;
          }, 1500);
          this.groupEditDeliveryInformation();
          await this.getOrdersFromRefs();
        }
      },
      async addPlannedDate(order, index)
      {
        try {
          const plannedDate = {
            asText: moment(this.plannedDate[index]).format("YYYY-MM-DD"),
            unix: moment(this.plannedDate[index]).unix(),
            dateObj: moment(this.plannedDate[index]).toDate(),
            dayOfWeek: moment(this.plannedDate[index]).day(),
            dayNum: moment(this.plannedDate[index]).date(),
            monthNum: moment(this.plannedDate[index]).month() + 1,
            year: moment(this.plannedDate[index]).year(),
            weekNum: moment(this.plannedDate[index]).week(),
            fromHourUnix: moment(this.plannedFromHour[index]).unix(),
            toHourUnix: moment(this.plannedToHour[index]).unix(), 
          };
          if ((plannedDate.toHourUnix < plannedDate.fromHourUnix) || isNaN(plannedDate.fromHourUnix) || isNaN(plannedDate.toHourUnix)) {
            console.error('Error: toHourUnix is lower than fromHourUnix');
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: `Godzina końcowa nie może być wcześniejsza niż godzina początkowa`,
              success: false
            });
            return;
          }
          await db.collection('Orders').doc(order.id).update({
            "delivery.date.planned": plannedDate
          })
          await db.collection('Routes').doc(this.route.id).update({
            "meta.lastModificationDate": moment().unix(),
            "meta.name": this.$store.state.userData.name + ' ' + this.$store.state.userData.surname
          })
          this.$store.dispatch("updateHistory", {
            message: "Zmieniono planowaną datę dostawy.",
            docId: order.id,
            collectionName: "Orders",
            routeId: this.route.id,
            details: [this.route.id]
          })
          this.ownChange = true;
          this.loadingOrders = true
          this.openCalendarView(index)
          await this.getOrdersFromRefs();
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona sukcesem!",
            subheader: `Planowana data dostawy została zmieniona.`,
            success: true
          }); 
        }
        catch(error) {
          this.ownChange = false;
          this.loadingOrders = false;
        }
      },
      createToggleLists() {
        for(let i=0;i<this.orders.length;i++){
          if(!this.orders[i].description || !this.orders[i].description.trim()) {
            this.descriptions[i] = false
          } else {
            this.descriptions[i] = true
          }
          this.calendarsView[i] = {
            view: false,
            date: false,
            fromHour: false,
            toHour: false
          }
        }
      },
      async addOrderDescription(order)
      {
        try {
          this.disableDescription = true;
          if(order.description !== undefined)
          {
            const orderDoc = db.collection("Orders").doc(order.id)
            await orderDoc.update({'description': order.description})

            this.$store.commit('setNotification',{
              show: true,
              head: "Opis zamówienia został zaktualizowany!",
              subheader: ``,
              success: true
            });

            setTimeout(() => {
              this.disableDescription = false;
            }, 1500);
          }
          else
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Za krótki opis zamówienia!",
              subheader: `Akcja zakończona niepowodzeniem!`,
              success: false
            });
          }
          setTimeout(() => {
            this.disableDescription = false;
          }, 1500);
        }
        catch (error)
        {
          console.log(error);  
        }
      },
      resetFilters() {
        this.pickedOption = "desc",
        this.pickedSort = "all",
        this.pickedCountry = 'all';
        this.pickedPostcode = 'all';
        this.pickedDateOfCreate = null;
        this.pickedDateOfCreateUnix = null
        this.pickedDateOfDelivery = null;
        this.pickedDateOfDeliveryUnix = null
        this.sortName = "creationDesc",
        this.sortType = {
          order: "desc",
          type: "meta.created.date"
        }
      },
      showFilters()
      {
        this.showStatusFilters = !this.showStatusFilters;
        if(!this.showStatusFilters)
        {
          this.resetFilters()
          this.getAllOrders()
        }
      },
      getPostcodeGroups()
      {
        let customPostcode = {
          country: {
            SelectInputDisplayName: 'Brak',
            code: 'Brak',
            flagUrl: 'https://png.pngtree.com/png-vector/20190225/ourmid/pngtree-question-mark-vector-icon-png-image_702552.jpg',
            name: 'Brak',
          },
          name: "WSZYSTKIE ZAMÓWIENIA"
        }
        this.dbListener = db.collection("PostcodeGroups").orderBy("meta.createdDate", "desc")
        .onSnapshot((querySnapshot) => {
          this.groups = [];
          querySnapshot.forEach((doc) => {
            this.groups.push(doc.data());
            this.postcodesList.push(...doc.data().postcodes)
          });
          this.groups.unshift(customPostcode)
          this.selectedGroups = customPostcode
        });
      },
      checkOrdersByDeliveryDate() {
        this.newCheckedOrders = []
        if(!this.ordersByDeliveryDate) {
          this.ordersByDeliveryDate = true
          this.allOrders.forEach(order => {
            if(order.delivery.date.suggested !== null){
            const compare =order.delivery.date.suggested.unix
            const start =this.date.start.seconds
            const end =this.date.end.seconds
            if(compare >= start && compare <= end) {
              this.newCheckedOrders.push(order)
            }
            }
          })
        }else{
          this.ordersByDeliveryDate = false
        }
        
      },
      async changeToZwrot(order)
      {
        try {
          let confimationObject = {
            status: 0,
            code: parseInt(randomstring.generate({
              length: 4,
              charset: 'numeric'
            })),
          };
          order.packages = [];
          const positions = Object.values(order.positions)
          positions.forEach((position, index) => {
            for(let y=0;y<position.product.amountOfPackages;y++)
            {
              const parcelId = `${index+1}_${order.id}:${position.id}_${y+1}`
              order.packages.push({
                id: parcelId,
                scanned: true,
                scannedInStorage: true,
                variantId: position.product.variantId,
                subtracted: true
              });
            }
          })
          await db.collection('Orders').doc(order.id).update({
            "statusId": 7,
            "delivery.date.dateConfirmationSent": false,
            "meta.lastModificationDate": moment().unix(),
            "packages": order.packages,
            "return": true,
            "confirmation": confimationObject
          });
        } catch (error)
        {
          console.log(error);  
        }
      },
      createSuggestedDate(date)
      {
        let start = null
        let end = null
        if(moment(date.start.seconds).isBefore(date.end.seconds))
        {
          // start = moment(date[0]).format("DD.MM.YYYY")
          // end = moment(date[1]).format("DD.MM.YYYY")
          start = moment.unix(date.start.seconds).format("DD.MM.YYYY")
          end = moment.unix(date.end.seconds).format("DD.MM.YYYY")
        }
        else
        {
          // start = moment(date[1]).format("DD.MM.YYYY")
          // end = moment(date[0]).format("DD.MM.YYYY")
          start = moment.unix(date.end.seconds).format("DD.MM.YYYY")
          end = moment.unix(date.start.seconds).format("DD.MM.YYYY")
        }
        let text = start + ' - ' + end
        
        return text
      },
      loadMoreProducts(){
        this.queryLimit += 10
        this.getAllOrders()
      },
      async changeView()
      {
        if(!this.addOrdersView)
        {
          this.switchLoading = true;
          await this.getAllOrders()
        }

        this.clearData()
        this.calculateStartingVolume()
        this.addOrdersView = !this.addOrdersView
      },
      async addOrders()
      {
        try {
          this.addOrderLoad = true;
          let preparedOrders = [];
          for(let i=0; i<this.orders.length; i++)
          {
            preparedOrders.push({
              id: this.orders[i].id,
              index: i
            })
          }
          for(let i=0; i<this.checkedOrders.length; i++) {
            preparedOrders.push({
              id: this.checkedOrders[i].id,
              index: preparedOrders.length
            });
          }
          const loadingSequence = await axios.post(`${this.$store.state.herokuApiLink}/routes/${this.$store.state.herokuApiPath}/loadingSequence/get`, {
            vehicleId: this.route.vehicle.id,
            orders: preparedOrders,
          }, {
            timeout: 20000,
            headers: {
                'x-api-jwt-token': this.$store.state.userData.jwt,
                'x-api-key': process.env.VUE_APP_APIKEY,
            },
          })
          if(!loadingSequence.data.success) throw loadingSequence.data.error.code !== 'error' ? loadingSequence.data.error.message : "Coś poszło nie tak podczas dodawania..."
          const res = await axios.post(`${this.$store.state.apiLink}/route/orders/add`, {
            orders: this.checkedOrders,
            loadingSequence: loadingSequence.data.payload.loadingSequence,
            packages: loadingSequence.data.payload.packages,
            route: this.route,
            byUser: {
              fullname: this.$store.state.userData.name + ' ' + this.$store.state.userData.surname,
              id: this.$store.state.userData.id,
            }
          })
          if(!res.data.success)
          {
            this.$store.commit('setNotification', {
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: "Zamówienie nie zostało dodane do trasy.",
              success: false
            });
            this.addOrdersView = false
            this.addOrderLoad = false;
          }
          else
          {
            this.ownChange = true;
            this.loadingOrders = false
            // this.$store.commit('setNotification',{
            //   show: true,
            //   head: "Akcja zakończona sukcesem!",
            //   subheader: "Zamówienie zostały zaktualizowane.",
            //   success: true
            // });
            for(let i=0; i<res.data.orders.length; i++)
            {
              this.toggleDescription[i] = {
                open: false,
              }
            }
            this.addOrdersView = false
            this.addOrderLoad = false;
          }
        } 
        catch (error) {
          console.log(error);
          this.$store.commit('setNotification', {
            show: true,
            head: "Zamówienie nie zostało dodane do trasy.",
            subheader: error,
            success: false
          });
          this.addOrdersView = false;
          this.addOrderLoad = false;
          this.checkedOrders = [];
        }
      },
      async deleteOrder(order)
      {
        try {
          this.loadingOrders = true

          const newOrders = this.orders.filter(el => el.id !== order.id)

          let preparedOrders = [];
          for(let i=0; i<newOrders.length; i++) {
            preparedOrders.push({
              id: newOrders[i].id,
              index: i
            });
          }
       
          const loadingSequence = await axios.post(`${this.$store.state.herokuApiLink}/routes/${this.$store.state.herokuApiPath}/loadingSequence/get`, {
            vehicleId: this.route.vehicle.id,
            orders: preparedOrders,
          }, {
            timeout: 20000,
            headers: {
                'x-api-jwt-token': this.$store.state.userData.jwt,
                'x-api-key': process.env.VUE_APP_APIKEY,
            },
          })
          if(!loadingSequence.data.success) throw "Something went wrong with loadingSequence"

          const res = await axios.post(`${this.$store.state.apiLink}/route/orders/delete`, {
            singleOrder: order,
            orders: newOrders,
            loadingSequence: loadingSequence.data.payload.loadingSequence,
            route: this.route,
            byUser: {
              fullname: this.$store.state.userData.name + ' ' + this.$store.state.userData.surname,
              id: this.$store.state.userData.id,
            }
          })

          if(!res.data.success)
          {
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: "Zamówienie nie zostało usunięte z trasy.",
              success: false
            });
          }
          else
          {
            this.ownChange = true;
            this.loadingOrders = true
            this.$store.dispatch('updateHistory', {
                collectionName: "Orders",
                docId: order.id,
                message: "Usunięto zamówienie z trasy.",
                routeId: this.route.id,
                details: [this.route.id]
            })
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona sukcesem!",
              subheader: "Zamówienie zostało usunięte.",
              success: true
            });
          }

        }
        catch (error)
        {
          console.log(error);
        }
      },
      calculateProducts(order)
      {
        let products = 0;
        Object.values(order.positions).forEach(position => {
          products += position.product.amountOfPackages * position.product.quantity
        }); 
        return products
      },
      createProductList(order)
      {
        const products = Object.entries(order.positions)
        let translatedListOfProducts = [];
        products.forEach(element => {
          if(element[0])
          {
            translatedListOfProducts.push(element[1].product.name);
          }
        })

        return translatedListOfProducts
      },
      getManufacturer(order)
      {
        const manufacturer = Object.entries(order.positions)
        let translatedListOfManufacturer = [];
        manufacturer.forEach(element => {
            if(element[0])
            {
                translatedListOfManufacturer.push(element[1].product.manufacturer);
            }
        })

        return translatedListOfManufacturer
      },
      getStatusById(id)
      {
        let currentStatus = {
          name: "Brak",
          info: "Brak",
          id: null
        }
        this.statuses.forEach((status) => {
          if(status.id === id){
            currentStatus = {
              name: status.name,
              info: status.info,
              id: status.id
            }
          }
        })
        return currentStatus
      },
      async getStatuses()
      {
        let res = await db.collection("OrderStatuses").doc("StatusList").get()
        let resData = res.data()
        this.statuses = resData.list
      },
      emitClose()
      {
        this.clearData()
        this.$emit("close");
      },
      emitAccept(data)
      {
        this.$emit("accept", data);
        this.$emit("emitClose");
      },
      returnEmit()
      {
        let newOrders = [];
        if(this.orders !== undefined)
        {
          for(let i=0; i<this.orders.length; i++)
          {
            newOrders.push({
              index: i,
              ref: db.collection('Orders').doc(this.orders[i].id)
            })
          }
        }

        this.$emit("manageRoute", newOrders);
      },
      totalPrice(positions, currency, deliveryPrice)
      {
        let totalPrice = 0;
        for (const pos in positions) {
            totalPrice = totalPrice + (positions[pos].product.quantity * positions[pos].product.pricePerItemTaxIncluded)
        }
        if(deliveryPrice > 0)
        {
            totalPrice = totalPrice + deliveryPrice;
        }
        return `${totalPrice} ${currency}`
      },
      amountOfItems(positions)
      {
        let posAm = 0;
        let items = 0;
        for (const pos in positions) {
            posAm = posAm +1
            items = items + positions[pos].product.quantity;
        }

        return `${posAm} poz. (${items} przedmiotów)`
      },
      async getCountries()
      {
        this.countriesData = [];
        const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
        if(res.data.status === 'SUCCESS')
        {
          // const excludedFromCountries = ["Åland Islands","Andorra","Gibraltar","Faroe Islands","Jersey","Guernsey","Isle of Man","Vatican City","Svalbard and Jan Mayen","San Marino","Russian Federation","Kosovo","North Macedonia","Russia"]
          res.data.countries.forEach(country => {
            // if(!excludedFromCountries.includes(country.name.common))
            // {
              this.countriesData.push({code: country.countryCode, flagURL: country.flagURL});
            // }
          });
        }
      },
      async getAllOrders()
      {
        this.load = true;
        this.query = null;
        this.queryLimit = 25;
        if(this.dbListener !== null){
            this.dbListener()
            this.dbListener = null
        }
        this.query = db.collection("Orders")

        switch(this.pickedSort)
        {
          case 'all':
            this.query = this.query.where("statusId", "in", [3, 400, 401, 403])
            break;
          case 'country':
            this.pickedCountry === 'all' ? (this.query = this.query.where("statusId", "in", [3, 400, 401, 403])) : (this.query = this.query.where("delivery.country.code", "==", this.pickedCountry === 'all' ? '' : this.pickedCountry).where("statusId", "in", [3, 400, 401, 403]))
            break;
          case 'suggested':
            this.query = this.query.where("statusId", "in", [3, 400, 401, 403]).orderBy("delivery.date.suggested", this.pickedOption)
            break;
          case 'code':
            this.query = this.query.where("statusId", "in", [3, 400, 401, 403]).orderBy("delivery.postCode", this.pickedOption)
            break;
          default:
            this.query = this.query      
            break;
        }
        let isEnded = null;
        if(this.isSelectedGroup)
        {
          isEnded = this.query
        }
        else
        {
          isEnded = this.query.limit(this.queryLimit)
        }
        this.dbListener = isEnded
        .onSnapshot((querySnapshot) => {
          this.toggleInformation = {};
          this.allOrders = [];
          this.lastLoaded = '';
          if(querySnapshot.docs.length<this.queryLimit)
          {
            this.queryLimitReached = true;
          }
          else
          {
            this.queryLimitReached = false;
          }
          querySnapshot.forEach((doc) => {
            let current = doc.data()
            current.countryFlagURL = "";
            if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
            {
              current.delivery.country.code = current.invoice.countryCode;
            }
              
            for(let y=0; y< this.countriesData.length; y++)
            {
              if(this.countriesData[y].code === current.delivery.country.code)
              {
                current.countryFlagURL = this.countriesData[y].flagURL;
              }
            }
            this.allOrders.push(current);
            this.lastLoaded = current.meta.created.date;

          });
          for(let t=0; t<this.allOrders.length; t++)
          {
            this.toggleInformation[t] = {
              open: false
            }
          }
          this.switchLoading = false;
          this.load = false;
        });
      },
      continueListening()
      {
        this.queryLimit = this.queryLimit + 25;
        this.dbListener = this.query.limit(this.queryLimit)
        .onSnapshot((querySnapshot) => {
          this.toggleInformation = {};
          this.allOrders = [];
          if(querySnapshot.docs.length<this.queryLimit)
          {
              this.queryLimitReached = true;
          }
          else
          {
              this.queryLimitReached = false;
          }
          querySnapshot.forEach((doc) => {
            let current = doc.data()
            current.countryFlagURL = "";
            if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
            {
              current.delivery.country.code = current.invoice.countryCode;
            }
            for(let y=0; y< this.countriesData.length; y++)
            {
              if(this.countriesData[y].code === current.delivery.country.code)
              {
                current.countryFlagURL = this.countriesData[y].flagURL;
              }
            }
            this.allOrders.push(current);
            this.lastLoaded = current.meta.created.date;
          });
          for(let t=0; t<this.allOrders.length; t++)
          {
            this.toggleInformation[t] = {
              open: false
            }
          }
        });
      },
      loadMoreOrders()
      {
        this.dbListener();
        this.continueListening();      
      },
      selectOrders(country, codes) {
        if(country === "Brak") {
          return this.allOrders
        }
        const countryOrders = this.allOrders.filter(order => order.delivery.country.code === country);
        const groupOrders = []

        switch (country) {
          case 'PL':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                groupOrders.push(order)
              }
            })
            break;
          case 'DE':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                  groupOrders.push(order)
              }
            })
            break;
          case 'FR':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                  groupOrders.push(order)
              }
            })
            break;
          case 'IT':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                  groupOrders.push(order)
              }
            })
            break;
          case 'NL':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                  groupOrders.push(order)
              }
            })
            break;
          case 'BE':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                  groupOrders.push(order)
              }
            })
            break;
          case 'AT':
            countryOrders.forEach(order => {
              const region = order.delivery.postCode.slice(0, 2)
              if(codes.includes(region)){
                  groupOrders.push(order)
              }
            })
            break;
          default:
            console.log(`Country ${country} not supported`);
            break;
        }

        return groupOrders
      },
      openAdditionalInformation(indx)
      {
        if(this.toggleInformation[indx].open)
        {
            this.toggleInformation[indx].open = false
        }
        else
        {
          for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
              this.toggleInformation[i].open = false
          }
          this.toggleInformation[indx].open = true
        }
      },
      openDescription(indx)
      {
        if(this.toggleDescription[indx].open)
        {
            this.toggleDescription[indx].open = false
        }
        else
        {
          for (let i = 0; i < Object.keys(this.toggleDescription).length; i++) {
              this.toggleDescription[i].open = false
          }
          this.toggleDescription[indx].open = true
        }
      },
      createPositionsList(ord)
      {
        const positions = Object.entries(ord.positions).map((arr) => ({
          item: arr[1]
        }));
        return positions
      },
      checkOrder(e, order, index)
      {
        if(e.target.checked)
        {
          order.index = index
          this.checkedOrders.push(order)
        }
        if(!e.target.checked)
        {
          for(let i=0; i<this.checkedOrders.length; i++)
          {
            if(this.checkedOrders[i].id === order.id)
            {
              this.checkedOrders.splice(i, 1)
            }
          }
        }
      },
      isOrderChecked(order) {
        let checked = false
        this.checkedOrders.forEach(orderData => {
          if(order.id === orderData.id) {
            checked = true
          }
        })
        return checked
      },
      calculateVolume(e, order)
      {
          if(e.target.checked)
          {
            Object.values(order.positions).forEach(position => {
              this.fullVolume = currency(this.fullVolume).add(position.volume);
              // this.fullVolume += Math.round(position.volume * 100) / 100;
            });
          }
          if(!e.target.checked)
          {
            Object.values(order.positions).forEach(position => {
              this.fullVolume = currency(this.fullVolume).subtract(position.volume);
              // this.fullVolume -= Math.round(position.volume * 100) / 100;
            });
          }
      },
      calculateStartingVolume(){
        this.orders.forEach(order => {
          Object.values(order.positions).forEach(position => {
              this.fullVolume = currency(this.fullVolume).add(position.volume);
              // this.fullVolume += Math.round(position.volume * 100) / 100;
            });
        })
      },
      clearData()
      {
        this.fullVolume = 0;
        this.checkedOrders = [];
      },
      async getOrdersFromRefs()
      {
        try {
          this.loadingOrders = true
          this.orders = []
          let indexes = [];
          let ordersRefs = [];
  
          for(let i=0; i<this.route.orders.length; i++)
          {
            ordersRefs.push(this.route.orders[i].ref.id)
            indexes.push(this.route.orders[i].index)
          }
  
          const res = await axios.post(`${this.$store.state.apiLink}/route/orders/get`, {
            orders: ordersRefs,
            indexes: indexes,
          })
          if(res.data.success)
          {
            this.orders = res.data.orders
            for(let i=0; i<this.orders.length; i++)
            {
              this.plannedDate[i] = this.orders[i].delivery.date.planned == null ? 'Brak' : moment.unix(this.orders[i].delivery.date.planned.unix).minutes(0).toDate()
              this.plannedFromHour[i] = {
                hours: this.orders[i].delivery.date.planned != null && this.orders[i].delivery.date.planned.fromHourUnix != null ? moment.unix(this.orders[i].delivery.date.planned.fromHourUnix).hour() : moment().hour(),
                minutes: this.orders[i].delivery.date.planned != null && this.orders[i].delivery.date.planned.fromHourUnix != null ? moment.unix(this.orders[i].delivery.date.planned.fromHourUnix).minutes(0).minute() : moment().minutes(0).minute()
              };
              this.plannedToHour[i] = {
                hours: this.orders[i].delivery.date.planned != null && this.orders[i].delivery.date.planned.toHourUnix != null ? moment.unix(this.orders[i].delivery.date.planned.toHourUnix).hour() : moment().hour(),
                minutes: this.orders[i].delivery.date.planned != null && this.orders[i].delivery.date.planned.toHourUnix != null ? moment.unix(this.orders[i].delivery.date.planned.toHourUnix).minutes(0).minute() : moment().minutes(0).minute()
              };
              this.toggleDescription[i] = {
                open: false
              };
            }
            this.orders.sort((a,b) => a.index - b.index)
          }
          else
          {
            this.orders = [];
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: res.data.log,
              success: false
            });
          }
          this.loadingOrders = false
        } catch (error) {
          console.log(error)
          this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: error.message,
            success: false
          });
        }
      },
      selectGroup(val)
      {
        this.isSelectedGroup = false;
        this.disableListener = false;
        this.resetFilters()
        if(val.country.code === 'Brak')
        {
          this.isSelectedGroup = false;
        }
        else
        {
          this.isSelectedGroup = true;
        }
        this.getAllOrders()
        // if(this.disableListener)
        // {
        //   if(this.isSelectedGroup)
        //   {
        //     this.allOrders = this.selectOrders(val.country.code, val.postcodes)
        //   }
        // }
      },
      async loadOrdersModal() {
        this.description = ''
        this.disableDescription = false
        this.changeDatePicker = false
        this.plannedDate = [];
        this.plannedFromHour = [];
        this.plannedToHour = [];
        this.descriptions = []
        this.toggleDescription = {}
        this.calendarsView = []
        this.newCheckedOrders =[]
        this.ordersByDeliveryDate = false
        this.loadProducts = false
        this.orders = []
        this.allOrders = []
        this.toggleInformation = {}
        this.checkedOrders = []
        this.fullVolume = 0
        this.addOrdersView = false
        this.queryLimitOrders = 25
        this.queryLimit = 10
        this.queryLimitReached = false
        this.groups = []
        this.selectedGroups = null
        this.postcodesList = []
        this.statuses = []
        this.showStatusFilters = false
        this.load = false
        this.loading = false
        this.loadingOrders = false
        this.dbListener = null
        this.showStatusFilters = false
        this.commentsModal = {
          toggle: false,
          data: null
        }
        this.pickedOption = "desc"
        this.pickedCountry = "all"
        this.pickedSort = "all"
        this.sortName = "creationDesc"
        this.sortType = {
            order: "desc",
            type: "meta.created.date"
        }
        this.pickedDateOfCreate = null
        this.pickedDateOfCreateUnix = null
        this.pickedDateOfDelivery = null
        this.pickedDateOfDeliveryUnix = null
        this.switchLoading = false
        this.reorder = false
        this.indexOrder = null
        this.addOrderLoad = false

        this.date = this.route.date
        await this.getOrdersFromRefs()
        this.getStatuses()
        this.getPostcodeGroups()
        this.calculateStartingVolume()
        this.createToggleLists()
      }
    },
    watch: {
      'route.meta.lastModificationDate.seconds'(a, b){
        if(a !== b) {
          this.loadOrdersModal()
        }
      },
      'route.sendingMessages'(a, b) {
        if(a !== undefined && a) {
          this.sendingMessage = true
        } else {
          this.sendingMessage = false
        }
      },
      'blockModal.state'() {
        if(this.blockModal.state && this.ownChange) {
          this.$emit('refreshModalData')
          this.ownChange = false
        }
      },
      'lastLoaded'(val){
        if(val)
        {
          this.dbListener();
          this.allOrders = this.selectOrders(this.selectedGroups.country.code, this.selectedGroups.postcodes)
        }
      },
    },
    async created()
    {
      await this.getCountries()
      await this.getSMSHelpers();
      this.loadOrdersModal();
    }
};
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
  transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
  opacity: 0;
  }

</style>
